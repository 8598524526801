import { Observable, OperatorFunction } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RsFormatFile } from '../../models/rs-format-file';

/**
 * Creates an operator function that formats an HTTP response containing a Blob into an RsFormatFile object.
 *
 * @description This custom pipe is designed to handle HTTP responses that contain file data as a Blob.
 * It extracts the file content and attempts to determine the filename from the Content-Disposition header.
 *
 * @returns An OperatorFunction that transforms HttpResponse<Blob> into RsFormatFile.
 *
 * @example
 * ```typescript
 * someHttpRequest()
 *   .pipe(
 *     rsFormatFile()
 *   )
 *   .subscribe(file => {
 *     console.log(file.fileName);
 *     console.log(file.blob);
 *   });
 * ```
 */
export function rsFormatFile(defaultFileName: string = 'file'): OperatorFunction<HttpResponse<Blob>, RsFormatFile> {
  return (source$: Observable<HttpResponse<Blob>>): Observable<RsFormatFile> => {
    return source$.pipe(
      map((res) => ({
        blob: res.body ?? new Blob(),
        fileName: res.headers.get('content-disposition')?.split('filename=')[1] ?? defaultFileName
      }))
    );
  }
}
